import { CountryData } from '@/components/form/phone-input/types';
import { TagItem } from '@/components/form/tags-selector/tags-selector';

export const DAYS_OF_WEEK = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
export const APPLE_STORE_LINK = "https://apps.apple.com/ua/app/maxima-therapy-app/id6615087427?l=en";
export const GOOGLE_STORE_LINK = "https://drive.google.com/drive/folders/1YWR9EPHYSjKVU64L48OMSUbOKWrFWxxz?usp=sharing";

export const DAYS_OF_MONTH = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export const SHIFT_STATUSES: string[] = ['Draft', 'Scheduled', 'Pending', 'Completed', 'No show', 'Canceled'];
export const PHONE_COUNTRIES: CountryData[] = process.env.NEXT_PUBLIC_ENV !== 'production' ? [['Ukraine', 'ua', '380', '(..) ... .. ..'], ['United States', 'us', '1', '(...) ...-....', 0]] : [['United States', 'us', '1', '(...) ...-....', 0]];

export enum SHIFT_STATUS_TYPES {
  DRAFT = 'Draft',
  SCHEDULED = 'Scheduled',
  PENDING = 'Pending',
  COMPLETED = 'Completed',
  NO_SHOW = 'No show',
  CANCELED = 'Canceled',
}

export enum AUTH_JWT_ACTIONS {
  REFRESH = 'REFRESH',
  REFRESH_MANUAL = 'REFRESH_MANUAL',
  UPDATE_ROLE = 'UPDATE_ROLE',
}

export const SHIFT_START_TIMES: string[] = [
  'This week',
  'This month',
  'This pay period',
  'Previous week',
  'Previous month',
  'Previous pay period',
];

export const SIGNATURE_START_TIMES: string[] = ['This month', 'Last month', 'This pay period', 'Last pay period'];

export const SHIFT_STATUSES_TAGS = (): TagItem[] =>
  SHIFT_STATUSES.map((v, id) => ({
    label: v,
    value: { id, value: v },
  }));

export enum DOCUMENT_TYPE_TYPES {
  'FACE' = 'FACE',
  'Progress' = 'Progress',
  'Discharge' = 'Discharge',
  'Assessment' = 'Assessment',
}
export const DOCUMENT_TYPES: DOCUMENT_TYPE_TYPES[] = [
  DOCUMENT_TYPE_TYPES.FACE,
  DOCUMENT_TYPE_TYPES.Progress,
  DOCUMENT_TYPE_TYPES.Discharge,
  DOCUMENT_TYPE_TYPES.Assessment,
];

export enum DOCUMENT_STATUS_TYPES {
  'Pending' = 'Pending',
  'Submitted' = 'Submitted',
  'Past due' = 'Past due',
  'Completed' = 'Completed',
  'Expired' = 'Expired',
}

export const DOCUMENT_STATUSES: DOCUMENT_STATUS_TYPES[] = [
  DOCUMENT_STATUS_TYPES.Pending,
  DOCUMENT_STATUS_TYPES.Submitted,
  DOCUMENT_STATUS_TYPES['Past due'],
  DOCUMENT_STATUS_TYPES.Completed,
];

export const ACTIVITY_ACTIONS = ['Create', 'Update', 'Soft delete', 'Restore'];
export const ACTIVITY_ENTITIES = [
  'users',
  'roles',
  'shifts',
  'clients',
  'devices',
  'services',
  'comments',
  'documents',
  'geofences',
  'locations',
  'user_roles',
  'departments',
  'permissions',
  'specialities',
  'service_roles',
  'service_shifts',
  'job_role_types',
  'therapist_rates',
  'client_guardians',
  'role_permissions',
  'guardian_services',
  'service_role_rates',
  'therapist_work_logs',
  'therapist_supervisors',
];

export const ALLOWED_MESSAGE_FILES = [
  'mpeg',
  'mpg',
  'mp4',
  'webm',
  'qt',
  'flv',
  '3gp',
  '3g2',
  'avi',
  'mov',
  'pdf',
  'doc',
  'docx',
  'potx',
  'ppsx',
  'ppt',
  'pptx',
  'rtf',
  'txt',
  'xls',
  'xlsx',
  'gif',
  'jpeg',
  'jpg',
  'png',
  // 'svg'
];

export enum ACTIVITY_ACTION_TYPES {
  CREATE = 'Create',
  UPDATE = 'Update',
  SOFT_DELETE = 'Soft delete',
  DEACTIVATE = 'Deactivate',
  RESTORE = 'Restore',
}

export enum SERVICE_TYPES {
  Assessment = 'Assessment',
  Ongoing = 'Ongoing'
}

export enum ACTIVITY_ENTITY_TYPES {
  USERS = 'users',
  ROLES = 'roles',
  SHIFTS = 'shifts',
  CLIENTS = 'clients',
  DEVICES = 'devices',
  SERVICES = 'services',
  COMMENTS = 'comments',
  DOCUMENTS = 'documents',
  GEOFENCES = 'geofences',
  LOCATIONS = 'locations',
  USER_ROLES = 'user_roles',
  DEPARTMENTS = 'departments',
  PERMISSIONS = 'permissions',
  SPECIALITIES = 'specialities',
  SERVICE_ROLES = 'service_roles',
  SERVICE_SHIFTS = 'service_shifts',
  JOB_ROLE_TYPES = 'job_role_types',
  THERAPIST_RATES = 'therapist_rates',
  CLIENT_GUARDIANS = 'client_guardians',
  ROLE_PERMISSIONS = 'role_permissions',
  GUARDIAN_SERVICES = 'guardian_services',
  SERVICE_ROLE_RATES = 'service_role_rates',
  THERAPIST_WORK_LOGS = 'therapist_work_logs',
  THERAPIST_SUPERVISORS = 'therapist_supervisors',
}
